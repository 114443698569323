import * as React from "react"

import ToolScreen from '../../../../components/toolScreen'


const Tool = ({ data }) => (
  <ToolScreen
    name="Canva"
    img_name="canva_logo.png"
    link="https://www.canva.com/"
    description="Canva is the best option for beginners looking for an easy-to-use graphic design tool to create social media posts, marketing materials, posters, and more."
    twitterHandleTool="@Canva"
  />
)

export default Tool;


